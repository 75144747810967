.panel-content {
    z-index: 10 !important;
    width: 100vw;
    height: 100%;
    position: absolute;
    overflow: hidden;
    margin-top: 44px;
}

.mobile-nav {
    position: fixed !important;
    z-index: 100000000000000000 !important;
    background-color: white;
}