.cs-message--outgoing .cs-message__content,
.cs-message__content-wrapper .cs-message__content {
    background-color: transparent !important;
}

.cs-main-container {
    border: none;
}

.cs-message:first-of-type {
    display: none;
}

.cs-message-input__content-editor-wrapper,
.cs-message-input__content-editor-container,
.cs-message-input__content-editor {
    background-color: transparent !important;
}

.cs-message-input__content-editor-wrapper {
    border: solid 1px #ECEEF0;
    border-radius: 50px;
    padding: 12px;
}

.cs-message__avatar {
    margin-top: 12px;
    justify-content: initial;
}

.cs-message:first-of-type {
    display: none;
}

.cs-chat-container .cs-message-input {
    border-top: none;
}

.cs-message-input__tools:first-of-type {
    display: none;
}

.cs-message__avatar {
    margin-top: 0px;
    width: auto;
}

.cs-message {
    margin-bottom: 1.6rem !important;
}

[aria-label="ChatGPT"] .cs-avatar.cs-avatar--md {
    padding: 3px !important;
}
.clickClose{
    position: fixed;
    width: 100%;
    z-index: 10000000000000000000;
    height: 100%;
    background: rgba(255, 0, 0, 0);
}

.cs-avatar.cs-avatar--md {
    min-width: 20px;
    min-height: 20px;
    width: 20px;
    height: 20px;
}

.cs-message__content {
    padding: 0px !important;
}

.cs-message-list__scroll-wrapper {
    padding: 0px !important;
}

.cs-typing-indicator__text,
.cs-typing-indicator__indicator {
    color: #687076 !important;
}

.cs-message-input__tools,
.cs-button {
    background-image: url(../../media/arrow.svg);
    background-repeat: no-repeat;
    background-size: cover;
}

.fa-paper-plane {
    color: transparent !important;
}

.cs-button {
    position: absolute;
    right: 10px;
    height: 25px;
    top: 13px;
    width: 25px;
}

.ps__thumb-y {
    background-color: #ECEEF0 !important;
}
.cs-message-input{
    position: fixed;
    bottom: 5px;
    width: 100%;
    width: 640px;
    padding-bottom: 25px;
    z-index: 10;
}

@media screen and (max-width:768px) {
    .cs-message-input {
        position: fixed;
        bottom: 10px;
        width: 94vw;
        left: 0;
        right: 0;
        width: 95vw;
        margin-left: 2vw;
    }
}