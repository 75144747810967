
.sidebar::-webkit-scrollbar-track {
    display:none;
}
.sidebar{
    z-index: 2 !important;
}
.active{
	background-color: #ECEEF0;
}
.backdrop{
	position: absolute;
    width: calc(100% - 240px);
    right: 0;
    height: 100%;
    background: rgba(255, 0, 0, 0);
    z-index: 100000;
}
.cs-conversation {
    padding: 0.1rem;
    margin: 0.35rem;
    background-color: transparent !important;
}

.custom-text-input > div > input {
    border-radius: 5px !important;
}

/* Tooltip.css */
.tooltip-container {
    position: relative;
    display: inline-block;
}

.tooltip-content {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
}

.tooltip-container:hover .tooltip-content {
    visibility: visible;
    opacity: 1;
}

.card > div{
    padding:0px !important;
    gap:0px !important;

}
