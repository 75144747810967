.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 94vh; /* Adjust height as needed */
}

.loading-circle {
  border: 4px solid #f3f3f35e; /* Light grey */
  border-top: 4px solid #c749d1; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

  